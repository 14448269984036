
    .location-btn {
        cursor: pointer;
        font-weight: bold;
    }
    ::v-deep .el-breadcrumb {
        .el-breadcrumb__inner {
            color: #000;
        }
    }
    .noSignIn {
        color: #FF0000;
    }
    .signInDialog {
        .el-dialog__header {border-bottom: 1px solid #f5f5f5;}
    }
    .signInHistory {
        .signInBtn {
            color: #FF0000;
            font-size: 14px;
        }
    }
    .modules-title {
        margin: 16px 0;
        font-size: 18px;
        color: #333333;
    }
    .myclass-main {
        box-sizing: border-box;
        width: 1200px;
        margin: 0 auto;
        padding-top: 20px;
        height: 100%;
        display: flex;
        flex-direction: column;
        .myclass-content {
            height: 1%;
            flex: 1;
            margin: 20px 0;
            display: flex;
            .myclass-left-bar {
                box-sizing: border-box;
                height: 100%;
                background-color: #FFFFFF;
                width: 300px;
                border-radius: 8px;
                box-shadow: 2px 2px 10px rgba(32, 32, 32, 0.05);
                padding: 0 0 16px 16px;
                ul, li {
                    list-style: none;
                    margin: 0;
                    padding: 0;
                }
                .class-stu-list {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    .class-list-header {
                        span {
                            display: inline-block;
                            margin-top: 16px;
                            color: rgba(51, 51, 51, 1);
                        }
                    }
                    .stu-list {
                        ::v-deep .el-scrollbar__wrap {
                            overflow-x: hidden;
                        }
                        .class-stus {
                            flex: 1;
                            li {
                                margin-top: 20px
                            }
                            .stu-info {
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                cursor: pointer;
                                .stu-avatar {
                                    width: 20px;
                                    height: 20px;
                                    overflow: hidden;
                                    border-radius: 50%;
                                    margin-right: 9px;
                                    img {
                                        width: 100%;
                                        height: 100%;
                                        max-width: 100%;
                                        max-height: 100%;
                                    }
                                }
                                .stu-name-item {
                                    font-size: 14px;
                                    color: #333333;
                                }
                            }
                        }
                    }
                }
            }
            .myclass-right-main {
                box-sizing: border-box;
                margin-left: 14px;
                width: 1%;
                flex: 1;
                height: 100%;
                border-radius: 8px;
                background-color: #FFF;
                box-shadow: 2px 2px 10px rgba(32, 32, 32, 0.05);
                display: flex;
                flex-direction: column;
                .myclass-r-header {
                    width: 100%;
                    .myclass-r-header-c {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        flex: 1;
                        box-sizing: border-box;
                        padding: 0 20px;
                        margin: 0 5px;
                        border-bottom: 1px solid #EAEAEA;
                        .r-header-c-tit {
                            h4 {
                                margin: 16px 0;
                                font-size: 18px;
                                color: #333333;
                            }
                        }
                    }
                }
                .myclass-r-content {
                    .myclass-tabs {
                        margin-top: 24px;
                        box-sizing: border-box;
                        flex: 1;
                        height: 120px;
                        padding: 0 20px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        .tab-item {
                            height: 100%;
                            flex: 1;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            border-radius: 8px;
                            margin-right: 13px;
                            cursor: pointer;
                            &:last-child {
                                margin-right: 0;
                            }
                            .tabs-item-contents {
                                display: flex;
                                flex: 1;
                                justify-content: flex-start;
                                align-items: flex-start;
                                .txt-part {
                                    p {
                                        padding: 0;
                                        margin: 0;
                                        color: #FFF;
                                        font-size: 16px;
                                        &.num-total {
                                            font-size: 20px;
                                            font-weight: bold;
                                        }
                                    }
                                }
                            }
                            &.practice-item {
                                background-image: linear-gradient(to right, #6BC0FF, #3A8CF6);
                                .icon-part {
                                    background-position: 0px -40px;
                                }
                            }
                            &.test-item {
                                background-image: linear-gradient(to right, #FFC443, #FF8A28);
                                .icon-part {
                                    background-position: -62px -40px;
                                }
                            }
                            &.homework-item {
                                background-image: linear-gradient(to right, #FF6B7D, #FF4C55);
                                .icon-part {
                                    background-position: -124px -40px;
                                }
                            }
                            &.discuss-item {
                                background-image: linear-gradient(to right, #47EAD1, #19C59F);
                                .icon-part {
                                    background-position: -185px -40px;
                                }
                            }
                            .icon-part {
                                width: 50px;
                                height: 50px;
                                border-radius: 10px;
                                margin: 0 24px;
                                background: url("../../assets/icons/sprite.png");
                            }
                        }
                    }
                    .myclass-courses {
                        .course-name {
                            padding-left: 25px;
                        }
                        .course-list {
                            margin: 0 18px;
                            height: 104px;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            .swiper-content {
                                box-sizing: border-box;
                                margin-left: 16px;
                                height: 100%;
                                flex: 1;
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                .course-panel-item {
                                    cursor: pointer;
                                    position: relative;
                                    height: 100%;
                                    flex: 1;
                                    border-radius: 8px;
                                    margin-right: 16px;
                                    .panel-modal, .txt-modal {
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        height: 100%;
                                        width: 100%;
                                        border-radius: 8px;
                                    }
                                    .panel-modal {
                                        background: rgba(0, 0, 0, 0.4);
                                    }
                                    .txt-modal {
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: center;
                                        align-items: center;
                                        p {
                                            text-align: center;
                                            width: calc(100% - 20px);
                                            font-size: 16px;
                                            margin: 10px;
                                            color: #FFF;
                                            white-space: nowrap;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                        }
                                    }
                                }
                            }
                            .pre-click-btn, .next-click-btn {
                                width: 24px;
                                height: 100%;
                                display: flex;
                                align-items: center;
                                a {
                                    display: block;
                                    text-align: center;
                                    line-height: 24px;
                                    width: 24px;
                                    height: 24px;
                                    border-radius: 12px;
                                    background-color: #FF0000;
                                    color: #FFFFFF;
                                }
                            }
                        }
                    }
                }
                .myclass-r-history {
                    height: 1%;
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    .history-tit {
                        padding-left: 25px;
                    }
                    .history-content {
                        width: 100%;
                    }
                }
            }

        }
    }
    .course-swiper-wrapper {
        position: relative;
        .course-swiper {
            margin: 0 42px;
            .swiper-slide {
                width: 100%;
                position: relative;
                border-radius: 8px;
                overflow: hidden;
                .course-cover {
                    width: 100%;
                    height: 0;
                    padding-bottom: 56.1798%;
                    position: relative;
                    background: #fdf6ef;
                    img {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        display: block;
                        max-width: 100%;
                        max-height: 100%;
                        transform: translate(-50%, -50%);
                    }
                }
                .course-content {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0, 0, 0, .4);
                    p {
                        color: #fff;
                        font-size: 18px;
                        margin: 20px 0 0;
                        padding: 0 10px;
                        text-align: center;
                    }
                }
            }
        }
        .banner-swiper-btn-prev,
        .banner-swiper-btn-next {
            position: absolute;
            top: 50%;
            bottom: 0;
            width: 22px;
            height: 22px;
            border-radius: 50%;
            background: #FF0000;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 10;
            cursor: pointer;
            outline: none;
            transform: translateY(-50%);
            img {
                display: block;
                width: 12px;
            }
        }
        .banner-swiper-btn-prev {
            left: 14px;
        }
        .banner-swiper-btn-next {
            right: 14px;
        }
    }
